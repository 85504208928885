import type { ReactElement } from 'react'
import React, {
  Children,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import StoriesProgressBar from './StoriesProgressBar'
import type { StoryProps } from './Story'

import Lemon from '~/lemonpie3/icons/lemon'
import { colors } from '~/lemonpie3/theme'

const FRAME_RATE = 100
const DEFAULT_STORY_DURATION = 10000

export type ThemeType = 'dark' | 'light'

const Stories = ({
  storyDuration = DEFAULT_STORY_DURATION,
  children,
}: {
  storyDuration?: number
  children: ReactElement<StoryProps> | ReactElement<StoryProps>[]
}): JSX.Element => {
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const progressIntervalRef = useRef<ReturnType<typeof setInterval>>()

  const storiesLength = Children.count(children)

  const setIntervals = useCallback(() => {
    const numberOfFrames = storyDuration / FRAME_RATE
    const percentagePerFrame = 100 / numberOfFrames

    progressIntervalRef.current = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + percentagePerFrame
        if (nextProgress >= 100) {
          setSelectedStoryIndex(
            (prevSelectedStoryIndex) =>
              (prevSelectedStoryIndex + 1) % storiesLength,
          )

          return 0
        }

        return nextProgress
      })
    }, FRAME_RATE)
  }, [storyDuration, storiesLength])

  const clearIntervals = () => {
    clearInterval(progressIntervalRef.current)
  }

  useEffect(() => {
    setIntervals()

    return () => {
      clearIntervals()
    }
  }, [setIntervals])

  const storiesArray = Children.toArray(children) as ReactElement<StoryProps>[]

  const selectedStory = storiesArray[selectedStoryIndex]

  const { theme } = selectedStory.props

  const handleContainerClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()

      clearIntervals()
      setIntervals()

      const screenHalfWidth = window.innerWidth / 2

      const isLeftClick = event.clientX < screenHalfWidth

      if (isLeftClick) {
        if (selectedStoryIndex !== 0) {
          setProgress(0)
          setSelectedStoryIndex(selectedStoryIndex - 1)
        }
      } else if (selectedStoryIndex !== storiesLength - 1) {
        setProgress(0)
        setSelectedStoryIndex(selectedStoryIndex + 1)
      }
    },
    [selectedStoryIndex, setIntervals, storiesLength],
  )

  return (
    <div
      className="flex flex-1 relative m-0"
      role="presentation"
      onClick={handleContainerClick}
    >
      <div className="absolute flex flex-col gap-[9px] w-full py-0 px-large">
        <StoriesProgressBar
          storiesLength={storiesLength}
          selectedStory={selectedStoryIndex}
          progress={progress}
          theme={theme}
        />
        <Lemon
          size={40}
          color={
            theme === 'dark'
              ? colors.foreground.neutral.primary.inverted
              : colors.foreground.identity.primary
          }
        />
      </div>
      {React.cloneElement(selectedStory, { theme })}
    </div>
  )
}

export default Stories

import {
  ConsumptionImage,
  EnvironmentImage,
  LemonAccountImage,
} from './components/images'
import MarketingStory from './components/MarketingStory'
import Stories from './components/Stories'

export default () => {
  return (
    <div className="flex flex-col grow">
      <Stories>
        <MarketingStory
          marketingTextStart="Veja&nbsp;suas contas&nbsp;Lemon"
          marketingTextEnd="quando&nbsp;quiser"
          backgroundImage={
            <LemonAccountImage className="absolute left-0 w-full z-0 top-[8%] h-[58%]" />
          }
          theme="dark"
          className="bg-expressive-strong"
        />
        <MarketingStory
          marketingTextStart="Acompanhe&nbsp;o seu&nbsp;consumo&nbsp;e"
          marketingTextEnd="a&nbsp;sua&nbsp;economia"
          backgroundImage={
            <ConsumptionImage className="absolute left-0 w-full z-0 top-[8%] h-[60%]" />
          }
          theme="light"
          className="bg-main"
        />
        <MarketingStory
          marketingTextStart="Saiba&nbsp;como você&nbsp;ajuda&nbsp;o"
          marketingTextEnd="meio&nbsp;ambiente"
          backgroundImage={
            <EnvironmentImage className="absolute left-0 w-full z-0 top-[8%] h-[61%]" />
          }
          theme="dark"
          className="bg-black-100"
        />
      </Stories>
    </div>
  )
}
